<script>
import Base from '@backend/Base.vue';

import BOGen from '@helper/BOGen';

export default {
	name: "BoHeartDisease",
	extends: Base,
  data(){
    return {
		Name:"BoHeartDisease",
		mrLevel: {},
		formResult: false,
		input:{},
		labelResult:'Add',
    }
  },
  mounted(){
    this.$set(this.$root, 'page', this)
	this.refreshData()
	},
  watch:{
    '$route.query'(){
		this.refreshData()
		if(this.$route.params.id){
			this.formResult = false
		}
	},
    'filter.level'(v){
		if(!v) return
		this.search()
	},
  },
  methods:{  
  	closeForm(){
  		this.formResult=false
  	},  	
    addRow(){
       this.row.answerd.push({answer:'',skorL:'',skorP:''});
    },
    addResult(){
    	this.labelResult = 'Add'
    	this.formResult=true
    	this.input={}
    	this.input.type = 'addResult'
    	this.$set(this.input, 'mhdr_is_active', 'Y')
    	this.$set(this.input, 'mhdr_type', 'L')
    	setTimeout(()=>{
    		$('html, body').animate({
	         	scrollTop: $("#formResult").offset().top
	     	}, 1200);
    	},500)
    },
    editResult(v){
    	console.log(v)
    	this.labelResult = 'Edit'
    	this.formResult=true
    	this.input = v
    	this.input.type = 'editResult'
    },
    submitFormResult(e) {
        if(e&&e.btnLoading()) return;
				BOGen.apirest('/' + this.Name, this.input, (err, resp) => {
          if(e) e.btnUnloading()
					if (resp.error) return Gen.info(resp.message, 'danger', 3000, '.col-info')
					if (resp.success) {
						Gen.info(resp.message, 'success', 2000, '.col-info')
            this.refreshData()
            setTimeout(() => {
              this.input={}
              this.formResult=false
            }, 1000)
          }
        }, "POST");
     },
  }
}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>		
		<div class="row" v-if="!$route.params.id">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
             <div class="card">
                <div class="card-body">
	                <VForm @resp="search">
						<div class="row">
							<div class="col-sm-5">
								<h5 class="card-title">Questions List</h5>
							</div>
							<div class="col-sm-3">
								
							</div>
							<div class="col-sm-3">
								<div class="form-group mb-0">
                                    <div class="input-group">
                                       <input type="text" v-model="filter.search" v-on:keyup.enter="search"  class="form-control" placeholder="Search...">
                                       <div class="input-group-append">
                                          <button class="btn btn-info" type="button" @click="search()"><i class="fas fa-search"></i></button>
                                       </div>
                                    </div>
                                 </div>
							</div>
							<div class="col-sm-1">
								<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
							</div>
						</div>
					</VForm>
                </div>
                <div class="table-responsive ajax-table">
					<table class="table table-striped table-bordered">
						<thead>
							<tr>
								<th>#</th>
								<th>{{fields.mhd_question}}</th>
								<th>{{fields.mhd_is_active}}</th>
								<th width="120px">Action</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(v,k) in data.data" :key="k">
								<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
								<td>{{v.mhd_question}}</td>
								<td><StatusLabel :status="v.status"></StatusLabel></td>
								<td class="btn-action">
									<router-link v-if="page.moduleRole('Edit') && v.mhd_id > 1" class="icon_action" :to="{name:Name,params:{id:v.id}}" v-tooltip="'Edit'"><i class="ti-marker-alt"></i></router-link>
									<a v-if="page.moduleRole('ChangeStatus') && v.mhd_id > 1" href="javascript:;" class="icon_action" @click="changeStatus(k)" v-tooltip="'Change Status'"><i class="icon-settings"></i></a>
									<a v-if="page.moduleRole('Delete') && v.mhd_id > 1" href="javascript:;" class="icon_action" @click="deleteItem($event,k)" v-tooltip="'Remove'"><i class="ti-trash"></i></a>
								</td>
							</tr>
							<tr v-if="NotFound">
								<td colspan="99">
									<h3 class="tc">{{NotFound}}</h3>
								</td>
							</tr>
							<tr v-if="data.data===false">
								<td colspan="99">
									<LoadingSpinner light></LoadingSpinner>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
             </div>
          </div>
       </div>


       	<div class="row" v-if="!$route.params.id">
	          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
	             <div class="card">
	                <div class="card-body">
	                	<div class="row">
	                		<div class="col-10">
	                			<h5 class="card-title">Result</h5>
	                		</div>
	                		<div class="col-2">
	                			<a href="javascript:;" @click="addResult" class="btn btn-info d-none d-lg-block m-l-15"><i class="fa fa-plus-circle  m-r-15"></i>Add Result</a>
	                		</div>
	                	</div>
	                </div>
        			<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>Parameter Logic</th>
									<th>Gender</th>
									<th>Status</th>
									<th width="120px">Action</th>
								</tr>
							</thead>
							<tbody v-if="dataResult.data.length > 0">
								<tr v-for="(v,k) in dataResult.data" :key="k" :id="k">
									<td class="number">{{(dataResult.per_page*(dataResult.current_page-1))+k+1}}</td>
									<td>{{v.mhdr_reason}}</td>
									<td>{{v.mhdr_type == 'L' ? 'Male' : 'Female'}}</td>
									<td><StatusLabel :status="v.status"></StatusLabel></td>
									<td class="btn-action">
										<a v-if="page.moduleRole('Edit')" class="icon_action" href="javascript:;" v-tooltip="'Edit'" @click="editResult(v)"><i class="ti-marker-alt"></i></a>
										<a v-if="page.moduleRole('Delete')" href="javascript:;" class="icon_action" @click="deleteItemId(v.mhdr_id,k)" v-tooltip="'Remove'"><i class="ti-trash"></i></a>
									</td>
								</tr>
							</tbody>
							<tbody v-else>										
								<tr>
									<td colspan="99">
										<h3 class="tc">No Result</h3>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
	            </div>
	        </div>
	    </div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="formResult" id="formResult">
			<VForm @resp="submitFormResult" method="post">
				<div class="card-body">
					<div class="col-info"></div>
					<div class="row mb-3">
                      <div class="col-md-11">
                         <h5 class="card-title">{{labelResult}} Result</h5>
                      </div>
                      <div class="col-md-1 text-right">
                      	<a href="javascript:;" v-tooltip="'Close'" @click="closeForm"><i class="fas fa-times-circle" style="font-size: 25px;"></i></a>
                      </div>
                   </div>
					<div class="info"></div>
					<div class="row">
						<div class="col-sm-6">
							<BoField name="mhdr_reason" :attr="{required:true}" mandatory v-model="input.mhdr_reason"></BoField>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-2">
							<BoField name="mhdr_operator1" mandatory>
								<select class="form-control" v-model="input.mhdr_operator1">
									<option v-for="(o,ko) in operator">{{o}}</option>
								</select>
							</BoField>
						</div>
						<div class="col-sm-2">
							<BoField name="mhdr_param1" mandatory :attr="{required:true,type:'number'}"  v-model="input.mhdr_param1"></BoField>
						</div>
						<div class="col-sm-2">
							<BoField name="mhdr_operator2">
								<select class="form-control" v-model="input.mhdr_operator2">
									<option v-for="(o,ko) in operator">{{o}}</option>
								</select>
							</BoField>
						</div>
						<div class="col-sm-2">
							<BoField name="mhdr_param2" :attr="{type:'number'}"  v-model="input.mhdr_param2"></BoField>
						</div>
						<div class="col-sm-12">							
							<BoField name="mhdr_result" mandatory :attr="{required:true}">
								<CKEditor name="mhdr_result"  v-model="input.mhdr_result" v-bind="validation('mhdr_result')"></CKEditor>
							</BoField>
						</div>
						<div class="col-6">							
							<BoField name="mhdr_type">
								<div class="row">
									<radio name="mhdr_type" v-model="input.mhdr_type" option="L" :attr="validation('mhdr_type')">Male</radio>
									<radio name="mhdr_type" v-model="input.mhdr_type" option="P">Female</radio>
								</div>
							</BoField>
						</div>
						<div class="col-6">							
							<BoField name="mhdr_is_active">
								<div class="row">
									<radio name="mhdr_is_active" v-model="input.mhdr_is_active" option="Y" :attr="validation('mhdr_is_active')">Active</radio>
									<radio name="mhdr_is_active" v-model="input.mhdr_is_active" option="N" >Inactive</radio>
								</div>
							</BoField>
						</div>
						<div class="col-12">
							<div class="text-right">
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading">Submit form <i class="icon-arrow-right14 position-right"></i></button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>

		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
                      <div class="col-md-8">
                         <h5 class="card-title">{{(row.id?"Edit":"Add")+" Question"}}</h5>
                      </div>
                   </div>
					<div class="info"></div>
					<div class="row">
						<div class="col-sm-6">
							<BoField name="mhd_question" v-model="row.mhd_question">
								<textarea name="mhd_question" v-bind="validation('mhd_question')" v-model="row.mhd_question" class="form-control"></textarea>
							</BoField>
							<div class="row" v-for="(v,k) in row.answerd">
								<div class="col-sm-12">
									<BoField name="answer" mandatory :attr="{required:true}" :label="'Answer'" v-model="v.answer"></BoField>
								</div>
								<div class="col-sm-4">
									<BoField name="skorL" mandatory :attr="{type:'number',required:true}" :label="'Score (Laki-laki)'" v-model="v.skorL"></BoField>
								</div>
								<div class="col-sm-4">
									<BoField name="skorP" mandatory :attr="{type:'number',required:true}" :label="'Score (Perempuan)'" v-model="v.skorP"></BoField>
								</div>
								<div class="col-sm-2">
									<a href="javascript:;" v-if="row.answerd.length>1" @click="row.answerd.splice(k,1)" v-tooltip="'Delete'" class="btn btn-danger btn-block" style="margin-top: 31px;"><i class="ti-trash"></i></a>
								</div>
								<div class="col-sm-2">
									<a href="javascript:;" @click="addRow" v-tooltip="'Add'" class="btn btn-info btn-block" style="margin-top: 31px;"><i class="ti-plus"></i></a>
								</div>
							</div>	
							
							<div class="row">	
								<div class="col-7">							
									<BoField name="mhd_is_active">
										<div class="row">
											<radio name="mhd_is_active" v-model="row.mhd_is_active" option="Y" :attr="validation('mhd_is_active')">Active</radio>
											<radio name="mhd_is_active" v-model="row.mhd_is_active" option="N">Inactive</radio>
										</div>
									</BoField>
								</div>
							</div>
						</div>
						<div class="col-12">
							<div class="text-right">
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading">Submit form <i class="icon-arrow-right14 position-right"></i></button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>
